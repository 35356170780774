import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { AnimatePresence, motion } from 'framer-motion';
import { useCookies } from 'react-cookie';

import { localizePath } from '@base/utils/helpers/localization/localize';
import { getPageURL } from '@base/utils/helpers/page-url';
import { LoadingContext } from '@base/utils/contexts/loader-context';
import trackLink from '@base/utils/helpers/tracking/track-link';
import Image from '@base/parts/image';
import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';

import Logo from '@elements/logo';
import { GateContext } from '@/utils/contexts/gate-context';
import { InteractiveContext } from '@/utils/contexts/interactive-context';
import triggerPageView from '@/utils/helpers/trigger-page-view';
import { soundEffects } from '@/utils/helpers/sound-effects';

function EndgameModal(props) {
  const [cookies, setCookie] = useCookies();
  const currentDomain = `${getPageURL('domain')}`;
  const cookieName = 'cofs-endgame';

  const { loading } = useContext(LoadingContext);
  const [isLoading, setLoading] = loading;

  const { mobileState } = useContext(GateContext);
  const [isMobile, setIsMobile] = mobileState;

  const { soundActive, endGame } = useContext(InteractiveContext);
  const [isSoundActive, setSoundActive] = soundActive;
  const [endGameActive, setEndGameActive] = endGame;
  const { globalData, pageContext } = props;
  const data = props.data.endGame;
  const brand = globalData.brandData;
  const stringsCta = globalData.stringsAll.cta;
  const { stringsLabels } = globalData;

  // Close Endgame Modal
  const closeEndGame = (route) => {
    setCookie(cookieName, true, { domain: `${currentDomain}`, path: '/' });

    // Send tracking data to the dataLayer
    const trackingData = {
      pageContext,
      event: route === 'website' ? 'the_website' : 'back_to_the_experiment',
      eventPage: 'interactive_desk_final',
      eventSection: 'interactive desk final modal',
    };
    trackLink(trackingData);

    if (route === 'website') {
      setLoading(true);
      navigate(localizePath({ ...pageContext, slug: '' }));
    } else {
      setEndGameActive(false);
    }
  };

  useEffect(() => {
    // Resize background to content height
    const parentHeight = document.querySelector('.modal-endgame')?.scrollHeight;
    const backdrop = document.querySelector('.modal-endgame-backdrop');

    if (backdrop) {
      backdrop.style.height = `${parentHeight}px`;
    }

    // Play Sound Effect
    if (endGameActive && isSoundActive) {
      soundEffects(5);
    }

    // Track Page View
    if (endGameActive) {
      triggerPageView('interactive_desk_final', pageContext);
    }
  }, [endGameActive]);

  const preOrderLink = {
    en: '/buy-now',
    fr: '/fr/achetez-maintenant',
    de: '/de/jetzt-kaufen',
    ja: '/ja/buy-now',
    pt: '/pt/comprar-agora',
    es: '/es/comprar-ya',
  };

  return (
    <AnimatePresence>
      {endGameActive && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className='modal-endgame absolute flex h-full w-full flex-col items-center justify-between'
        >
          <div />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className='modal-endgame-content py-16'
          >
            <div className='flex w-full flex-col items-center justify-center'>
              <Logo globalData={globalData} className='mb-28' />

              {isMobile ? (
                <h3 className='multi-line mb-16 text-center'>
                  {data.title && <span>{data.title}</span>}
                  {data.title2 && <span>{data.title2}</span>}
                </h3>
              ) : (
                <Heading
                  text={`${data.title} ${data.title2}`}
                  tag='h3'
                  className='mb-16 text-center'
                  animate={['opacity']}
                />
              )}
              <Markdown className='px-8 text-center font-body text-sm text-white lg:text-base'>
                {data.text}
              </Markdown>
              <Heading
                text={`${data.subTitle} ${data.subTitle2}`}
                tag='h4'
                className='forward my-16 text-center'
                animate={['opacity']}
              />
              {stringsCta.buyNow && (
                <Button
                  link={preOrderLink[pageContext.locale]}
                  trigger='internalLink'
                  pageContext={pageContext}
                  className='large'
                  eventAction='buy_now_intent'
                  eventPage='interactive_desk_final'
                  eventSection='interactive desk final modal'
                  gsEvent='buy_now_click'
                >
                  <span>{stringsCta.buyNow}</span>
                </Button>
              )}
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            className='modal-endgame-nav flex w-full justify-between space-x-4 px-4 py-8 align-bottom md:space-x-8 lg:px-16 lg:py-14 xl:space-x-4'
          >
            {stringsCta.backExperiment && (
              <button
                type='button'
                onClick={() => closeEndGame('experiment')}
                className='button button-secondary relative z-10 inline-flex items-center justify-center'
              >
                <span>
                  <StaticImage
                    src='../../../assets/img/icons/arrow.png'
                    alt=''
                    className='icon-arrow reverse mr-2 h-6 w-6'
                    placeholder='blurred'
                    loading='lazy'
                  />
                  {stringsCta.backExperiment}
                </span>
              </button>
            )}
            {stringsLabels?.logoTagLine && (
              <span className='hidden items-center justify-center whitespace-nowrap font-tagLine text-base uppercase text-white md:text-md lg:flex xxl:text-lg'>
                {stringsLabels?.logoTagLine}
                <Image
                  media={brand?.tagLineLogo}
                  className='-mt-[1px] ml-4 h-[1.2rem] w-auto md:h-[1.9rem] xxl:-mt-[2px] xxl:h-[2.2rem]'
                />
              </span>
            )}
            {/* {stringsCta.skipWebsite && (
              <button
                type='button'
                onClick={() => closeEndGame('website')}
                className='button button-secondary relative z-10 inline-flex items-center justify-center'
              >
                <span>
                  {stringsCta.skipWebsite}
                  <StaticImage
                    src='../../../assets/img/icons/arrow.png'
                    alt=''
                    className='icon-arrow ml-2 h-6 w-6 rotate-90'
                    placeholder='blurred'
                    loading='lazy'
                  />
                </span>
              </button>
            )} */}
          </motion.div>
          <div className='modal-endgame-backdrop absolute left-0 top-0 z-under h-full min-h-full w-full' />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default EndgameModal;
