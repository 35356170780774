import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import SVG from 'react-inlinesvg';

import Markdown from '@base/parts/markdown';
import Link from '@base/parts/link';
import Button from '@base/parts/button';

import separator from '@img/ui/separator.svg';
import { GarageContext } from '@/utils/contexts/garage-context';
import rewardImage from '../../../assets/img/garage/garage-reward-image.jpg';

function Endgame({ data, pageContext, strings }) {
  const { directives } = useContext(GarageContext);
  const [directiveActive, setDirectiveActive] = directives;

  const preOrderLink = {
    en: '/buy-now',
    fr: '/fr/achetez-maintenant',
    de: '/de/jetzt-kaufen',
    ja: '/ja/buy-now',
    pt: '/pt/comprar-agora',
    es: '/es/comprar-ya',
  };

  const trackingData = {
    pageContext,
    eventAction: 'learn_more',
    eventPage: 'garage_hangout_final',
    eventSection: 'garage final modal',
  };

  return (
    directiveActive === 4 && (
      <div className='qte-endgame absolute top-0 flex flex-col items-center justify-between'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className='flex flex-col items-center justify-center'
        >
          <h2 className='multi-line mark h3 centered mb-12 text-center'>
            {data?.title && (
              <span className='pointer-events-auto'>
                <mark>{data?.title}</mark>
              </span>
            )}
            {data?.subTitle && (
              <span className='pointer-events-auto'>
                <mark>{data?.subTitle}</mark>
              </span>
            )}
          </h2>

        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          className='flex flex-col items-center justify-center'
        >
          <Button
            link={rewardImage}
            title='the-casting-of-frank-stone-exclusive-image'
            trigger='download'
            pageContext={pageContext}
            label={strings.actions.download}
            className='button-secondary button-download'
            eventAction='download'
            eventPage='garage_hangout_final'
            eventSection='garage final modal'
            trackable
          >
            <span className='absolute' />
          </Button>
          <SVG
            cacheRequests
            preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
            src={separator}
            className='my-14 h-2 w-[120rem] max-w-[90vw]'
          />
          <Button
            link={preOrderLink[pageContext.locale]}
            trigger='internalLink'
            pageContext={pageContext}
            className='larger mb-8'
            eventAction='buy_now_intent'
            eventPage='garage_hangout_final'
            eventSection='garage final modal'
            gsEvent='buy_now_click'
          >
            <span>{strings.cta.buyNow}</span>
          </Button>
          {data.ctaLink && data.ctaText && (
            <Link
              to={data.ctaLink}
              className='button-text'
              trackClick={trackingData}
            >
              <Markdown>{data.ctaText}</Markdown>
            </Link>
          )}
        </motion.div>
      </div>
    )
  );
}

export default Endgame;
